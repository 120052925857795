import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../../hooks/useAuth';
import { useSnackbar } from 'notistack';

const GuestGuard = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  if (isAuthenticated) {
    enqueueSnackbar('Login Successful', {
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
    },
      variant: 'success'
    })
    return <Redirect to="/app/admin/Candidiates" />;

  }

  return (
    <>
      {children}
    </>
  );
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
