import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import SettingsLayout from 'src/views/Test';
import MainLayout from 'src/layouts/MainLayout';
import LoadingScreen from 'src/components/LoadingScreen/index';
import AuthGuard from 'src/components/Guards/AuthGuard';
import GuestGuard from 'src/components/Guards/GuestGuard';
import HomeView from 'src/views/home/HomeView';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen open={true} />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={props => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    path: '/accessdenied',
    component: lazy(() => import('src/views/errors/AccessDenied'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView/JWTLogin'))
  },

  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,

    path: '/reset_password',
    component: lazy(() => import('src/views/auth/LoginView/Reset_Password'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/forgot',
    component: lazy(() => import('src/views/auth/LoginView/Forgot_Password'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/confirm',
    component: lazy(() =>
      import('src/views/auth/LoginView/Confirm_forgot_password')
    )
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/confirmSignUp',
    component: lazy(() => import('src/views/auth/LoginView/confirmSignUp'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/confirmSignUpp',
    component: lazy(() =>
      import('src/views/auth/LoginView/confirmSignUpafterLogin')
    )
  },

  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app/admin/dashboard',
        component: lazy(() => import('src/views/Dashboard'))
      },
      {
        exact: true,
        path: '/app/admin/reports',
        component: lazy(() => import('src/views/Reports'))
      },
      {
        exact: true,
        path: '/app/admin/home',
        component: lazy(() => import('src/views/Dashboard'))
      },
      {
        exact: true,
        path: '/app/admin/:type',
        component: lazy(() => import('src/views/AllCandidates'))
      },

      {
        exact: true,
        path: '/app/admin/organization',
        component: lazy(() => import('src/views/Organization'))
      },
      // {
      //   exact: true,
      //   path: '/app/admin/:id/Details',
      //   component: lazy(() => import('src/views/CandidateDetails'))
      // },
      {
        exact: true,
        path: '/app/admin/:type/Details/:user_id',
        component: lazy(() => import('src/views/CandidateDetails'))
      },
      {
        exact: true,
        path: '/app/admin/:type/Details/:user_id',
        component: lazy(() => import('src/views/CandidateDetails'))
      },
      {
        exact: true,
        path: '/app/admin/WorkFlow/Details/:user_id/Job',
        component: lazy(() => import('src/views/WorkFlowJob'))
      },
      {
        exact: true,
        path: '/app/admin/:type/Details/:user_id/New',
        component: lazy(() => import('src/views/CandidateDetails/NewPage'))
      },
      {
        exact: true,
        path: '/app/admins/WorkFlow',
        component: lazy(() => import('src/views/AllWorkFlow'))
      },
      {
        exact: true,
        path: '/app/admins/WorkFlowGen',
        component: lazy(() => import('src/views/AllWorkFlowGen'))
      },
      {
        exact: true,
        path: '/app/admin/:type/Details/:user_id/New1',
        component: lazy(() => import('src/views/WorkFlowJob/NewPage'))
      },
      {
        exact: true,
        path: '/app/admin/:type/Add',
        component: lazy(() => import('src/views/AddDetails'))
      },
      {
        exact: true,
        path: '/app/Contacts',
        component: lazy(() => import('src/views/Contacts'))
      },
      {
        exact: true,
        path: '/app/organization',
        component: lazy(() => import('src/views/Organization'))
      },
      {
        exact: true,
        path: '/app/Jobs',
        component: lazy(() => import('src/views/Jobs'))
      },
      {
        exact: true,
        path: '/app/admin/student/:email',
        component: lazy(() =>
          import('src/views/AllStudents/StudentDetailsView')
        )
      },
      {
        exact: true,
        path: '/app/admin/user_management/dummy/404',
        component: lazy(() => import('src/views/UserManagaement'))
      },
      {
        exact: true,
        path: '/app/settings',
        component: lazy(() => import('src/views/Settings'))
      },
      {
        layout: SettingsLayout,
        exact: true,
        path: '/app/settings/:name',
        component: lazy(() => import('src/views/Settings/EmailTemplate'))
      },
      {
        layout: SettingsLayout,
        exact: true,
        path: '/app/Setting/GeneralSettings/Personal',
        component: lazy(() => import('src/views/Settings/Personal'))
      },
      {
        layout: SettingsLayout,
        exact: true,
        path: '/app/Setting/GeneralSettings/NotificationSettings',
        component: lazy(() => import('src/views/Settings/NotificationSettings'))
      },
      {
        layout: SettingsLayout,
        exact: true,
        path: '/app/Setting/GeneralSettings/Company',
        component: lazy(() => import('src/views/Settings/Company'))
      },
      
      {
        layout: SettingsLayout,
        exact: true,
        path: '/app/Setting/NotificationSettings/:id',
        component: lazy(() => import('src/views/Settings/EmailNotification'))
      },
      
      {
        layout: SettingsLayout,
        exact: true,
        path: '/app/Setting/Usermanagement/Users/:user',
        component: lazy(() => import('src/views/Settings/Users'))
      },
      {
        layout: SettingsLayout,
        exact: true,
        path: '/app/Setting/Usermanagement/AccessControll/:user',
        component: lazy(() => import('src/views/Settings/AccessControll'))
      },
      // {
      //   layout: SettingsLayout,
      //   exact: true,
      //   path: '/app/Setting/DataManagement/:id',
      //   component: lazy(() => import('src/views/Settings/Log'))
      // },
      
      {
        layout: SettingsLayout,
        exact: true,
        path: '/app/Setting/Integration/:id',
        component: lazy(() => import('src/views/Settings/Integration'))
      },
      {
        layout: SettingsLayout,
        exact: true,
        path: '/app/Setting/DataManagement/BackupDetails',
        component: lazy(() => import('src/views/Settings/BackupData'))
      },
      {
        layout: SettingsLayout,
        exact: true,
        path: '/app/Setting/DataManagement/ImportDetails',
        component: lazy(() => import('src/views/Settings/ImportData'))
      },
      {
        layout: SettingsLayout,
        exact: true,
        path: '/app/Setting/DataManagement/log',
        component: lazy(() => import('src/views/Settings/Log'))
      },
      {
        exact: true,
        path: '/app/kanban',
        component: lazy(() => import('src/views/kanban/KanbanView'))
      },
      {
        layout: SettingsLayout,
        exact: true,
        path: '/app/Setting/DataManagement/Archive',
        component: lazy(() => import('src/views/Settings/Archive'))
      },
      {
        layout: SettingsLayout,
        exact: true,
        path: '/app/Setting/DataManagement/ImportDetails/Datamigration',
        component: lazy(() => import('src/views/Settings/DataMigration'))
      },
      {
        layout: SettingsLayout,
        exact: true,
        path: '/app/Setting/DataManagement/ExportDetails',
        component: lazy(() => import('src/views/Settings/ExportData'))
      },
      {
        layout: SettingsLayout,
        exact: true,
        path: '/app/Setting/Compliance/GDPR',
        component: lazy(() => import('src/views/Settings/GDPR'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
 
  // {
  //   path: '/app/settings/:name',
  //   guard: AuthGuard,
  //   layout: SettingsLayout,
  //   routes: [
  //     {
  //       exact: true,
  //       path: '/app/settings/:name',
  //       component: lazy(() => import('src/views/Settings/EmailTemplate'))
  //     },

  //     {
  //       component: () => <Redirect to="/404" />
  //     }
  //   ]
  // },
  // {
  //   path: '/app',
  //   guard: AuthGuard,
  //   layout: AllApps,
  //   routes: [
  //     {
  //       exact: true,
  //       path: '/app/admin/applications/detail',

  //       component: lazy(() => import('src/views/Applications/AppDetails'))
  //     },
  //   ]
  // },

  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: HomeView
      },

      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
